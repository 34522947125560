import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import TaxiRates from '../components/Taxi-Rates'
const TaxiRate = () => (
	<Layout>
        <SEO
            title="Taxi Airport Eindhoven - Taxi Eindhoven Airport"
            meta="Taxi Airport Eindhoven, Taxi Eindhoven Airport"
						description="Taxi Eindhoven Airport Service at the best reasonable prices.
						We offer low prices Taxi Airport Eindhoven serves a wide range of vehicles."
        />
		<TaxiRates />
	</Layout>
)

export default TaxiRate
