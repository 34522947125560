import React, { Component, useContext } from "react";
import { Table } from "reactstrap";

import { LanguageContext } from "../../../lang";
import "./index.css";

const RatesTable = () => {
  const {
    translations: { taxiRates },
  } = useContext(LanguageContext);
  const {
    basicCharges,
    p1to2,
    p3to6,
    additional,
    waiting,
    secondHeading,
    thirdHeading,
    placesHeading,
    calculate,
  } = taxiRates.table;
  return (
    <div className="container">
      <Table bordered responsive>
        <thead>
          <tr>
            <th></th>
            <th>{basicCharges}</th>
            <th>{p1to2}</th>
            <th>{p3to6}</th>
            <th>{additional}</th>
            <th>{waiting}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Tagsüber</td>
            <td>€ 3,50</td>
            <td>€ 2,00</td>
            <td>€ 1,85</td>
            <td>€ 1,55</td>
            <td>€ 36,00</td>
          </tr>
        </tbody>
      </Table>

      <div>{secondHeading}</div>

      <br />
      <Table bordered responsive>
        <thead>
          <tr>
            <th></th>
            <th>{basicCharges}</th>
            <th>{p1to2}</th>
            <th>{p3to6}</th>
            <th>{additional}</th>
            <th>{waiting}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Nachts</td>
            <td>€ 3,50</td>
            <td>€ 2,10</td>
            <td>€ 1,90</td>
            <td>€ 1,65</td>
            <td>€ 36,00</td>
          </tr>
        </tbody>
      </Table>

      <div>{thirdHeading}</div>
      <br />
      <h3>{placesHeading}</h3>
      <div>{calculate}</div>
    </div>
  );
};

export default RatesTable;
