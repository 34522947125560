import React, { Component, useContext } from "react"

import { LanguageContext } from '../../../lang'
import "./index.css"

const KnowledgeSection = () => {
  const {
    translations: { taxiRates }
  } = useContext(LanguageContext)
  const {
    preHeading,
    heading,
    paragraph
  } = taxiRates.knowledgeSection

  return (
    <React.Fragment>
      <div className="row mt-4">
        <div className="col-12 text-center">
          {/* <h3 className="knowledge-h3">{preHeading}</h3> */}
          <h2 className="knowledge-h2">{heading}</h2>
          <hr className="knowledge-head-hr" />
        </div>
      </div>

      <div className="row pt-1">
        <div className="col-lg-12">
          <p className="knowledge-text text-center">
            {paragraph}
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default KnowledgeSection
