import React from "react";

import "./index.css";
import HeroSection from "./Hero-Section";
import KnowledgeSection from "./Knowledge-Section";
import RatesTable from "./Table";
import RideDescription from "./Description-Section";

const TaxiRates = () => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <HeroSection />
        <KnowledgeSection />
        <RatesTable />
        <br />
      </div>
    </React.Fragment>
  );
};

export default TaxiRates;
